import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Link } from "gatsby"

export default () => (
  <Layout>
    <SEO
      title="Business Developer - Careers at D2i Technology"
      description="Join our team as a Business Developer. 6-8 years of IT experience required to identify new opportunities and drive revenue growth, with essential skills in negotiation, communication, and a dynamic approach."
    />
    <section id="career-detail" className="career-detail">
      <div className="container" data-aos-todo="fade-up">
        <div className="section-title">
          <h1>Business Developer</h1>
        </div>
        <div className="row mt-5">
          <div className="col-lg-8 mx-auto">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/careers">Careers</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Business Developer
                </li>
              </ol>
            </nav>
            <div className="job-card">
              <div className="job-header">
                <h2>Job Description</h2>
                <Link
                  to="https://forms.gle/mCYwx55YhT6vA1cJ7"
                  target="_blank"
                  className="btn btn-primary"
                >
                  Apply Now
                </Link>
              </div>
              <p>
                We are seeking an experienced Business Developer with a strong
                IT background to join our dynamic team. The ideal candidate will
                be responsible for identifying new opportunities and driving
                revenue growth through their excellent negotiation and
                communication skills.
              </p>

              <h3>Requirements:</h3>
              <ul>
                <li>6-8 years of experience in IT industry</li>
                <li>
                  Proven track record in identifying and securing new business
                  opportunities
                </li>
                <li>Strong negotiation and communication skills</li>
                <li>Dynamic and proactive approach to business development</li>
                <li>Understanding of current IT trends and technologies</li>
                <li>
                  Ability to build and maintain strong client relationships
                </li>
                <li>
                  Experience in creating and delivering compelling presentations
                </li>
              </ul>

              <h3>Responsibilities:</h3>
              <ul>
                <li>
                  Identify and pursue new business opportunities in the IT
                  sector
                </li>
                <li>
                  Develop and implement effective sales strategies to drive
                  revenue growth
                </li>
                <li>
                  Build and maintain relationships with key clients and partners
                </li>
                <li>
                  Collaborate with internal teams to develop tailored solutions
                  for clients
                </li>
                <li>Negotiate contracts and close deals</li>
                <li>
                  Analyze market trends and competitor activities to inform
                  business strategies
                </li>
                <li>
                  Represent the company at industry events and conferences
                </li>
              </ul>

              <h3>What We Offer:</h3>
              <ul>
                <li>Competitive salary and commission structure</li>
                <li>
                  Opportunities for career advancement and professional
                  development
                </li>
                <li>Collaborative and innovative work environment</li>
                <li>Work-life balance with flexible work arrangements</li>
                <li>
                  Chance to work with cutting-edge technologies and diverse
                  clients
                </li>
              </ul>
            </div>
            <div className="apply-footer">
              <p>Ready to drive our business growth?</p>
              <Link
                to="https://forms.gle/mCYwx55YhT6vA1cJ7"
                target="_blank"
                className="btn btn-primary"
              >
                Apply for this Position
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)
